import './index.css';
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Video } from './styled';

const App = () => {
    const [config, setConfig] = useState(window.initialConfig ?? null);
    window.setConfig = setConfig;

    if (!config) {
        return null;
    }

    const {
        videoUrl,
        width,
        height
    } = config ?? {};

    const handleError = event => {
        // eslint-disable-next-line no-console
        console.log({
            type: 'playbackError',
            event
        });
        window.ReactNativeWebView?.postMessage?.(JSON.stringify({ type: 'playbackError' }));
    };

    return (
        <Video
            url={videoUrl}
            width={width}
            height={height}
            onError={handleError}
            config={{
                youtube: {
                    playerVars: {
                        controls: 1
                    }
                },
                facebook: {
                    attributes: {
                        'data-width': width,
                        'data-allowfullscreen': true,
                        'data-controls': true
                    }
                }
            }}
            controls
            playsinline
        />
    );
};

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
